import * as React from 'react'
import onClickOutside from 'react-onclickoutside'

export class OutsideClickHandler extends React.Component {
    static defaultProps = { onClickOutside: () => {} }
    handleClickOutside = (event) => this.props.onClickOutside(event)
    render = () => {
        const { children } = this.props
        if (React.Children.count(children) > 1) {
            throw new Error('click outside should handle exactly one container')
        }
        return children
    }
}

export default onClickOutside(OutsideClickHandler)
