import React from 'react'

import Trans from '../../components/trans'

const NoResults = ({ search }) => (
    <div className="no-results">
        <p>
            <Trans label="search.no-result.msg" />
        </p>
        <p className="term">{`“${search}”`}</p>
    </div>
)

export default NoResults
