import classnames from 'classnames'
import Link from 'next/link'

import Figure from '../../components/figure'

interface Props {
    url?: string
    title?: string
    subtitle?: string
    image?: string
    active?: string
    onClick?: () => void
    figure?: string
}

const SearchResult = ({
    url,
    title,
    subtitle,
    image,
    active,
    onClick,
    figure,
}: Props) => (
    <li className={classnames('omni-search-item', { active })}>
        <Link href={url}>
            <a onClick={onClick}>
                {!figure && <Figure url={image} />}
                {figure}
                <div className="omni-search-text">
                    <div className="omni-search-title">{title}</div>
                    {subtitle && (
                        <div className="omni-search-subtitle">{subtitle}</div>
                    )}
                </div>
            </a>
        </Link>
    </li>
)

export default SearchResult
