import React from 'react'
import classNames from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'

const AddClassToLink = React.forwardRef(
    ({ href, children, className, isActive, exact, ...props }, ref) => {
        const route = useRouter()

        const pathWithoutSearch = route.asPath
            .split('?')
            .shift()
            .split('#')
            .shift()

        const hrefWithoutSearch = href.split('?').shift().split('#').shift()

        let active = false

        if (exact && hrefWithoutSearch === pathWithoutSearch) {
            active = true
        }

        if (!exact && pathWithoutSearch.startsWith(href)) {
            active = true
        }

        if (typeof isActive === 'function') {
            active = isActive(active, route)
        }

        return (
            <a
                {...props}
                ref={ref}
                href={href}
                className={classNames(className, { active })}
            >
                {children}
            </a>
        )
    }
)

const NavLink = ({ href, ...props }) => {
    return (
        <Link href={href} passHref>
            <AddClassToLink {...props} />
        </Link>
    )
}

export default NavLink
