import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'

const LinkToSelf = ({ children }) => {
    const { asPath } = useRouter()
    return (
        <Link href={asPath}>
            <a>{children}</a>
        </Link>
    )
}

export default LinkToSelf
