import React from 'react'
import classnames from 'classnames'

import '../../../scss/react/components/skeleton-loading.scss'

interface SkeletonLoadingProps {
    className?: string
    white?: boolean
    brand?: boolean
    success?: boolean
    warning?: boolean
    danger?: boolean
    width?: number
    min?: number
    max?: number
    unit?: 'px' | '%'
    lineHeight?: number
}

const SkeletonLoading = ({
    className,
    white,
    brand,
    success,
    warning,
    danger,
    width,
    min,
    max,
    unit,
    lineHeight,
}: SkeletonLoadingProps) => (
    <span
        className={classnames('skeleton-loading', className, {
            'skeleton-brand': brand,
            'skeleton-success': success,
            'skeleton-warning': warning,
            'skeleton-danger': danger,
            'skeleton-white': white,
        })}
        style={{
            width: width
                ? `${width}${unit}`
                : `${Math.random() * (max - min) + min}${unit}`,
            lineHeight: `${lineHeight}px`,
        }}
    >
        &nbsp;
    </span>
)

SkeletonLoading.defaultProps = {
    min: 50,
    max: 100,
    unit: '%',
    lineHeight: 16,
}

export default SkeletonLoading
