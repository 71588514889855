import React from 'react'
import LinkToSelf from '../../router/link-to-self'
import Figure from '../../components/figure'
import SkeletonLoading from '../../components/skeleton-loading'

interface SkeletonProps {
    subtitle?: boolean
}

const Skeleton = ({ subtitle }: SkeletonProps) => (
    <li>
        <LinkToSelf>
            <Figure />
            <div className="omni-search-text">
                <div className="omni-search-title">
                    <SkeletonLoading lineHeight={16} />
                </div>
                {subtitle && (
                    <div className="omni-search-subtitle">
                        <SkeletonLoading max={40} lineHeight={12} />
                    </div>
                )}
            </div>
        </LinkToSelf>
    </li>
)

export default Skeleton
